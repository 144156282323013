import React from 'react'
import QCDashboard from "@mui/icons-material/Phone";
import ReportIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import BusinessIcon from "@mui/icons-material/Business";
import VendIcon from "@mui/icons-material/ShoppingCart";
import GavelIcon from "@mui/icons-material/Gavel";
import VendorTypeIcon from "@mui/icons-material/Category";
import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
}

const AdminMainMenuCards: React.FC<Props> = () => {

  const options = [
    { name: 'QC Dashboard', icon: <QCDashboard fontSize="large" />, link: '/dashboards/qc' },
    { name: 'Reporting', icon: <ReportIcon fontSize="large" />, link: '/reports' },
    { name: 'Campaigns', icon: <CampaignIcon fontSize="large" />, link: '/campaigns' },
    { name: 'Law Firms', icon: <BusinessIcon fontSize="large" />, link: '/law-firms' },
    { name: 'Vendors', icon: <VendIcon fontSize="large" />, link: '/vendors' },
    { name: 'Torts', icon: <GavelIcon fontSize="large" />, link: '/torts' },
    { name: 'Vendor Types', icon: <VendorTypeIcon fontSize="large" />, link: '/vendor-types' }
  ];

  return (
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          {options.map((option, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Link to={option.link} style={{ textDecoration: 'none' }}>
                  <Card sx={{ height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
                    <CardActionArea sx={{ textAlign: 'center', width: '100%', height: '100%' }}>
                      {option.icon}
                      <Typography variant="h6" component="div">
                        {option.name}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
          ))}
        </Grid>
      </Box>
  );
}

export default AdminMainMenuCards