import React, { FC } from 'react';
import { useLogout, Menu, MenuItemLink } from 'react-admin';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import LockResetIcon from '@mui/icons-material/LockReset';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import CampaignIcon from '@mui/icons-material/Campaign';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GavelIcon from '@mui/icons-material/Gavel';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { getRoleFromToken } from "../../authService";
import QCDashboard from '@mui/icons-material/Phone';

interface Props {
    onMenuItemClicked: () => void
}

const CustomMenu: FC<Props> = (props) => {
    const { onMenuItemClicked } = props
    const logout = useLogout();

    const role = getRoleFromToken()

    const handleSignOut = () => {
        logout();
        onMenuItemClicked?.()
    };

    return (
        <Menu>
            <Menu.DashboardItem onClick={() => onMenuItemClicked?.()} />

            {role === 'admin' && (
                <>
                    <MenuItemLink
                        to="/dashboards/qc"
                        primaryText="QC Dashboard"
                        leftIcon={<QCDashboard />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/admin"
                        primaryText="Admin Home"
                        leftIcon={<AdminPanelSettings />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/users"
                        primaryText="Users"
                        leftIcon={<PeopleIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/campaigns"
                        primaryText="Campaigns"
                        leftIcon={<CampaignIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/reports"
                        primaryText="Reports"
                        leftIcon={<AssessmentIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />


                    <MenuItemLink
                        to="/law-firms"
                        primaryText="Law Firms"
                        leftIcon={<LocationCityIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/torts"
                        primaryText="Torts"
                        leftIcon={<GavelIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/vendors"
                        primaryText="Vendors"
                        leftIcon={<StorefrontIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    <MenuItemLink
                        to="/vendor-types"
                        primaryText="Vendor Types"
                        leftIcon={<AssignmentReturnIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />


                    {/*<MenuItemLink*/}
                    {/*    to="/returns"*/}
                    {/*    primaryText="Returns"*/}
                    {/*    leftIcon={<ListAltIcon />}*/}
                    {/*    onClick={() => onMenuItemClicked?.()}*/}
                    {/*/>*/}

                    <MenuItemLink
                        to="/return-reasons"
                        primaryText="Return Reasons"
                        leftIcon={<ListAltIcon />}
                        onClick={() => onMenuItemClicked?.()}
                    />

                    {/*<MenuItemLink*/}
                    {/*    to="/return-statuses"*/}
                    {/*    primaryText="Return Statuses"*/}
                    {/*    leftIcon={<ListAltIcon />}*/}
                    {/*    onClick={() => onMenuItemClicked?.()}*/}
                    {/*/>*/}
                </>
            )}

            <MenuItemLink
                to="/change-password"
                primaryText="Change Password"
                leftIcon={<LockResetIcon />}
                onClick={() => onMenuItemClicked?.()}
            />

            <MenuItemLink
                to="#"
                primaryText="Sign Out"
                leftIcon={<ExitToAppIcon />}
                onClick={handleSignOut}
            />

            {/*<Menu.Item to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />} />*/}
        </Menu>
    )
}

export default CustomMenu
