

export const getIntOrDefault = (value: string | undefined | null, defaultValue: number = 0) : number => {
    if(value === undefined || value === null){
        return  defaultValue
    }
    const parsedIntValue = parseInt(value);
    if(isNaN(parsedIntValue)){
        return defaultValue
    }

    return parsedIntValue
}

export const getNumbersFromString = (value: string) : string  => {
    if(!value){
        return ''
    }
    return value.replace(/\D/g, '')
};

