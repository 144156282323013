import { IIntake } from "./campaignService";
import { get } from "./api";
import { getNumbersFromString } from "../utils";
import { ILawFirm } from "../types/ILawFirm";
import { IUser } from "../types/IUser";

export const getLawFirmCampaignQCSummaries = async (lawFirmId: number): Promise<ILawFirmCampaignCountRecord | undefined> => {
    const response = await fetch(`/api/law-firms/${lawFirmId}/qc-summaries`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return undefined
}

export const getLawFirmCampaigns = async (lawFirmId: number) => {
    try {
        const response = await fetch(`/api/law-firms/${lawFirmId}/campaigns`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json(); // Correct method to parse the response body as JSON
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return undefined
    }
}

export const getLawFirmIntake = async (campaignId: number, phoneNumber: string): Promise<IIntake | undefined> => {
    const cleanedPhoneNumber = getNumbersFromString(phoneNumber)
    return await get<IIntake>(`/campaigns/${campaignId}/law-firm-intakes/${cleanedPhoneNumber}`);
}

export const getLawFirm = async (lawFirmId: number) => {
    const results = await get<ILawFirm>(`/law-firms/${lawFirmId}`)
    if(results){
        return results
    }else{
        return undefined
    }
}

export const getLawFirmByName = async (lawFirmName: string) => {
    const encodedName = encodeURIComponent(lawFirmName);
    const results = await get<ILawFirm[]>(`/law-firms?law_firm_name=${encodedName}`)
    if(results && results.length > 0){
        return results[0]
    }else{
        return undefined
    }
}

export const getLawFirmUsers = async (lawFirId: number): Promise<IUser[]> => {
    const result = await get<IUser[]>(`/law-firms/${lawFirId}/users`);
    return result ?? [];
};