import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { getLawFirm, getLawFirmByName, getLawFirmCampaigns } from "../../services/lawFirmService";
import {
    downloadCampaignLawFirmIntakes,
    getCampaign,
    getCampaignLawFirmIntakes,
    IIntake,
    postTurnDown
} from "../../services/campaignService";
import Paper from "@mui/material/Paper";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";
import LawFirmActionMenu from "../../components/common/LawFirm/LawFirmActionMenu";
import { getLawFirmFromToken, getToken } from "../../authService";
import { useAuthenticated } from "react-admin";
import IntakeDetailDialog from "../../components/common/intakes/IntakeDetailDialog";
import TurnDownDialog from "../../components/common/LawFirm/TurnDownDialog";
import Button from "@mui/material/Button";
import { ILawFirm } from "../../types/ILawFirm";
import { getUserLawFirms } from "../../services/userService";

interface Props {}

const LawFirmDashboard: React.FC<Props> = () => {
    const [lawFirmName, setLawFirmName] = useState<string>('')

    const [allLawFirms, setAllLawFirms] = useState<ILawFirm[]>([])
    const [campaigns, setCampaigns] = useState<ICampaignOption[]>([]);
    const [currentCampaign, setCurrentCampaign] = useState<IDashboardCampaign | undefined>(undefined)
    const [selectedCampaignId, setSelectedCampaignId] = useState<number | string>('');
    const [totalSentToLawFirm, setTotalSentToLawFirm] = useState<number>(0)
    const [totalAcceptedByLawFirm, setTotalAcceptedByLawFirm] = useState<number>(0)
    const [gridData, setGridData] = useState<any[]>([]);

    const [viewMoreDlgOpen, setViewMoreDlgOpen] = useState<boolean>(false)
    const [viewMoreIntake, setViewMoreIntake] = useState<IIntake | undefined>(undefined)
    const [turnDownIntake, setTurnDownIntake] = useState<IIntake | undefined>(undefined)

    const [turnDownDlgOpen, setTurnDownDlgOpen] = useState<boolean>(false)

    const { search } = useLocation()
    const queryParams = new URLSearchParams(search);
    const viewAsLawFirmId = queryParams.get('lawFirmId');

    const law_firm = getLawFirmFromToken();

    useAuthenticated()

    useEffect(() => {
        const fetchLawFirms = async () => {
            const lawfirms = await getUserLawFirms()
            setAllLawFirms(lawfirms)
        }
        fetchLawFirms()
    }, [viewAsLawFirmId, law_firm]);

    useEffect(() => {
        const fetchLawFirmCampaigns = async  () => {
            // ADMIN MODE
            if(viewAsLawFirmId){
                const c = await getLawFirmCampaigns(parseInt(viewAsLawFirmId))
                const lf = await getLawFirm(parseInt(viewAsLawFirmId))
                setLawFirmName(lf?.name ?? '')
                setCampaigns(c)
            } else if(law_firm){
                const roleLawFirm = await getLawFirmByName(law_firm);
                if(roleLawFirm){
                    setLawFirmName(roleLawFirm?.name)
                    if(allLawFirms.length > 1){
                        console.log('all', allLawFirms)
                        const allCampaigns = []
                        for(let lf of allLawFirms){
                            console.log('law firm', lf)
                            const lawFirmCampaigns = await getLawFirmCampaigns(lf.id)
                            console.log('lawFirmCampaigns', lawFirmCampaigns)
                            allCampaigns.push(...lawFirmCampaigns)
                        }
                        setCampaigns(allCampaigns)
                    }else{
                        const c = await getLawFirmCampaigns(roleLawFirm.id)
                        setCampaigns(c)
                    }
                }
            }
        }
        fetchLawFirmCampaigns();

    }, [allLawFirms, law_firm, viewAsLawFirmId]);

    useEffect(() => {
        setCurrentCampaign(undefined)
        setGridData([])
        setTotalSentToLawFirm(0)
        setTotalAcceptedByLawFirm(0)

        const fetechCampaign = async  () => {
            const c = await getCampaign(selectedCampaignId as number)
            setCurrentCampaign(c)
        }

        const fetechCampaignIntakes = async  () => {
            const intakes = await getCampaignLawFirmIntakes(selectedCampaignId as number)
            setGridData(intakes?.intakes ?? [])
            setTotalSentToLawFirm(intakes?.sent_to_law_firm ?? 0)
            setTotalAcceptedByLawFirm(intakes?.accepted_by_law_firm ?? 0)
        }

        if(selectedCampaignId){
            fetechCampaign()
            fetechCampaignIntakes()
        }
    }, [selectedCampaignId]);



    const handleCampaignChange = (event: any) => {
        setSelectedCampaignId(event.target.value);
    };

    const handleViewMoreClicked = (intake: IIntake) => {
        setViewMoreIntake(intake)
        setViewMoreDlgOpen(true)
    }

    const handleViewMoreDlgClosed = () => {
        setViewMoreIntake(undefined)
        setViewMoreDlgOpen(false)
    }

    const handleTurnDownClicked = (intake: IIntake) => {
        setTurnDownDlgOpen(true)
        setTurnDownIntake(intake)
    }

    const  handleTurnDownDlgSaved = async (reason: string) => {
        await postTurnDown({ return_reason_id: parseInt(reason), campaign_id: currentCampaign?.id ?? 0, phone_number: turnDownIntake?.phone_number ?? '', injured_party_name: turnDownIntake?.injured_party_name ?? '' })
        setTurnDownDlgOpen(false)
    }

    const handleTurnDownDlgClosed = () => {
        setTurnDownDlgOpen(false)
    }

    const exportToCsv = async () => {
        if(currentCampaign?.id){
            await downloadCampaignLawFirmIntakes(currentCampaign.id)
        }
    }

    const columns = [
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => <LawFirmActionMenu onTurnDownClicked={handleTurnDownClicked} onViewIntakeClicked={handleViewMoreClicked} law_firm_id={currentCampaign?.law_firm_id ?? 0} campaign_id={currentCampaign?.id ?? 0} intake={params.row} />,
        },
        { field: 'qc_status', headerName: 'QC Status', width: 200 },
        {
            field: 'date',
            headerName: 'QC Date',
            width: 150,
            valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : '',
        },
        {
            field: 'submitted_to_law_firm_date',
            headerName: 'Submitted Date', width: 150,
            valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : '',
        },
        { field: 'injured_party_name', headerName: 'Injured Party Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 150 },
        { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    ];

    const rows = gridData.map((row, index) => ({ id: index, ...row }));

    return (
        <Box>
            <Box>
                <Typography variant="h1">Welcome {lawFirmName}</Typography>
                <Typography variant="body1">Please select a campaign to view its submitted intakes and details.</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box width={250}>
                    <FormControl fullWidth>
                        <InputLabel id="law-firm-campaigns-label">Campaigns</InputLabel>
                        <Select
                            labelId="law-firm-campaigns-label"
                            id="law-firm-campaigns-select"
                            value={selectedCampaignId}
                            label="Campaign"
                            onChange={handleCampaignChange}
                        >
                            {campaigns.map((campaign) => (
                                <MenuItem key={campaign.id} value={campaign.id}>
                                    {campaign.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {currentCampaign && (
                    <Box ml={2}>
                        <Button variant="contained" color="primary" onClick={exportToCsv} >
                            Export Data
                        </Button>
                    </Box>
                )}
            </Box>

            {currentCampaign && (
              <>
                  <Box sx={{ display: 'flex' }}>
                      <Typography mb={1} variant={'h6'}>Total Received: {totalSentToLawFirm}</Typography>
                      <Typography mb={1} sx={{ marginLeft: 2 }} variant={'h6'} color={'red'}>Total Accepted: {totalAcceptedByLawFirm}</Typography>
                  </Box>

                  <Paper style={{ height: 600, width: '100%' }}>
                      <DataGrid
                          rows={rows}
                          columns={columns}
                      />
                  </Paper>
              </>
            )}
            {(viewMoreIntake && viewMoreDlgOpen) && <IntakeDetailDialog intake={viewMoreIntake} onClose={handleViewMoreDlgClosed} />}
            {(turnDownDlgOpen) && <TurnDownDialog onSave={handleTurnDownDlgSaved} onCancel={handleTurnDownDlgClosed}/>}
        </Box>
    )
}

export default LawFirmDashboard