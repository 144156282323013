

export const getQCDashboardIntakeCounts = async (): Promise<ILawFirmCampaignCountRecord []> => {
    const response = await fetch(`/api/dashboards/active-counts`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return []
}