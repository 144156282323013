import { IReturnOption } from "../components/common/ReturnButton";
import { IReturnStatusOption } from "../components/common/AdminChangeStatusButton";


export const getReturnOptions = async () : Promise<IReturnOption[]> => {
    const response = await fetch(`/api/return-reasons`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return []
}

export const processReturn = async (transferNoteId: number, reasonId: number, status: string) => {
    await fetch(`/api/transfer-notes/${transferNoteId}/return`, {
        method: 'POST',
        body: JSON.stringify({
            transfer_note_id: transferNoteId,
            reason_id: reasonId,
            status
        }),
        headers: {
            'Content-Type': 'application/json',
        }
    })
}


export const getReturnStatusOptions = async () : Promise<IReturnStatusOption[]> => {
    const response = await fetch(`/api/return-statuses`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return []
}

export const processChangeStatus = async (transferNoteId: number, status: string) => {
     await fetch(`/api/transfer-notes/${transferNoteId}/status`, {
        method: 'POST',
        body: JSON.stringify({
            transfer_note_id: transferNoteId,
            status
        }),
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

