import React, { useCallback, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, Link, Tooltip } from '@mui/material';
import { ats_default_blue_alt } from "../../../atsConstants";
import CustomDialog from "../CustomDialog";
import { getCampaignIntakes } from "../../../services/campaignService";
import DynamicDataGrid from "../DynamicDataGrid";
import TableContainer from "@mui/material/TableContainer";
import Paper from '@mui/material/Paper';

interface Props {
    lawFirmCampaignCountRecords: ILawFirmCampaignCountRecord []
}

const QCCounts: React.FC<Props> = (props) => {
    const { lawFirmCampaignCountRecords } = props

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [tableData, setTableDate] = useState<any []>([])

    const handleShowDetail = useCallback((campaignId: number, status: string) => {
        const getAndFilterIntakeData = async () => {
            const campaignSummary = await getCampaignIntakes(campaignId)
            if (campaignSummary) {
                const filtered = campaignSummary.intakes.filter(i => i.qc_status === status)
                setTableDate(filtered)
                setDialogOpen(true)
            }
        }

        getAndFilterIntakeData()

    }, [])

    return (
        <>
            <Box>
                {lawFirmCampaignCountRecords.map(({ law_firm, campaign_count_records }) => (
                    <Box key={law_firm} mb={4}>
                        <Typography variant="h4" mb={1} sx={{ color: ats_default_blue_alt }}>{law_firm}</Typography>
                        {campaign_count_records.map((campaign) => (
                            <Box key={campaign.campaign_name} mb={3}>
                                <Typography variant="h6">{campaign.tort_name} - {campaign.campaign_name}</Typography>
                                <Typography variant="subtitle1">Total Retainers: {campaign.retainers}</Typography>

                                <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                                    <Tooltip
                                    placement={'right'}
                                    title={'(Sent to Law Firm + Replacement Sent) - (DQ by Firm + Unresponsive)'}
                                    arrow
                                    >
                                        <Typography variant="subtitle1">
                                            Sent to Law Firm: {campaign.sent_to_law_firm}
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                                    <Tooltip
                                        placement={'right'}
                                        title="Sent to Law Firm = Submitted to Law Firm + DQ by Firm + Unresponsive + Unresponsive Transferred to Firm"
                                        arrow
                                    >
                                        <Typography variant="subtitle1">
                                            Accepted by Law Firm: {campaign.accepted_by_law_firm}
                                        </Typography>
                                    </Tooltip>
                                </Box>


                                <Typography variant="subtitle1" color={'red'}>Remaining
                                    Needed: {campaign.remaining_needed}</Typography>
                                <Grid container spacing={2} mt={1}>
                                    {campaign.counts.map((count, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <Card>
                                                <CardContent sx={{ '&:last-child': { padding: 1 } }}>
                                                    <Box
                                                        onClick={() => handleShowDetail(campaign.campaign_id, count.name)}>
                                                        <Typography
                                                            variant="body1">{count.name || 'Unnamed'}</Typography>
                                                        <Typography variant="h6">
                                                            <Link
                                                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                onClick={() => handleShowDetail(campaign.campaign_id, count.name)}>
                                                                {count.count}
                                                            </Link>
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
            <CustomDialog title={'Details'} open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Box>
                    <TableContainer component={Paper}>
                        <DynamicDataGrid data={tableData}/>
                    </TableContainer>
                </Box>
            </CustomDialog>
        </>
    );
};

export default QCCounts;
