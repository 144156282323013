import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
import { getLawFirmFromToken, getRoleFromToken } from "../authService";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
    const headers = new Headers(options.headers || {});
    headers.append('Accept', 'application/json');

    const lawFirm = getLawFirmFromToken();
    if (url.includes('/transfer-notes') && lawFirm) {
        headers.append('X-Law-Firm', lawFirm);
    }

    const role = getRoleFromToken();
    if (role) {
        headers.append('X-Role', role);
    }

    options.headers = headers;

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider('/api', httpClient);
export default dataProvider;