import React from 'react'
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
}

const ReportsHome: React.FC<Props> = () => {

  const options = [
    { name: 'QC Intakes', icon: <SettingsAccessibilityIcon />, link: '/reports/qc-intakes' },
  ];

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            {options.map((option, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Link to={option.link} style={{ textDecoration: 'none' }}>
                    <Card sx={{ height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
                      <CardActionArea sx={{ textAlign: 'center', width: '100%', height: '100%' }}>
                        {option.icon}
                        <Typography variant="h6" component="div">
                          {option.name}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
            ))}
          </Grid>
        </Box>
    )
}

export default ReportsHome