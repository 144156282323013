import React from 'react'
import AuthChangePassword from "../components/auth/AuthChangePassword";

interface Props {
}

const ChangePassword: React.FC<Props> = () => {
    return (
        <AuthChangePassword />
    )
}

export default ChangePassword
