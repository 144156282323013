import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { Card, CardContent, Box, Link } from '@mui/material';
import { ats_default_blue } from "../atsConstants";
import ATSLogo from '../assets/ats-logo.png'

const LoginPage: React.FC = () => {

    return (
        <Login
            sx={{
                background: `linear-gradient(45deg, ${ats_default_blue} 30%, #5a6b7c 90%)`, // Adjust the gradient as needed
                '& .RaLogin-card': {
                    boxShadow: 'none'
                }
            }}
        >
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <img src={ATSLogo} alt="ATS Logo" style={{ height: '60px' }} />
                    </Box>
                    <LoginForm />
                    <Box>
                        <Link color="primary" href="/forgot-password">
                            Forgot Password?
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        </Login>
    )
};

export default LoginPage;
