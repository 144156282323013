import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from "@mui/material";
import { getQCDashboardIntakeCounts } from "../../../services/dashboardService";
import QCCounts from "../../../components/common/AdminDashboard/QCCounts";
import { useAuthenticated } from "react-admin";
import QuickSummary from "../../../components/common/AdminDashboard/QuickSummary";

interface Props {}

export const QCDashboard: React.FC<Props> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [campaignCountRecords, setCampaignCountRecords] = useState<ILawFirmCampaignCountRecord[]>([])

    useAuthenticated()

    useEffect(() => {
        const getQCDashboardCounts = async () => {
            setLoading(true)
            const counts = await getQCDashboardIntakeCounts();
            setCampaignCountRecords(counts)
            setLoading(false)
        }

        getQCDashboardCounts()
    }, []);

    return (
        <Box>
            <Box mb={2}>
                <Typography variant={'h1'}>Dashboard</Typography>
            </Box>

            <Box>
                <Typography variant={'h2'} mb={2}>QC Counts</Typography>
                <Box>
                    {loading && <CircularProgress/>}
                    <QuickSummary lawFirmCampaignCountRecords={campaignCountRecords} />
                    <QCCounts lawFirmCampaignCountRecords={campaignCountRecords} />
                </Box>
            </Box>
        </Box>
    )
}