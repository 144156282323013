import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from "@mui/material";
import { getQCDashboardIntakeCounts } from "../../../services/dashboardService";
import { useAuthenticated } from "react-admin";
import QuickSummary from "../../../components/common/AdminDashboard/QuickSummary";
import AdminMainMenuCards from "../../../components/common/admin/AdminMainMenuCards";

interface Props {}
export const Dashboard: React.FC<Props> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [campaignCountRecords, setCampaignCountRecords] = useState<ILawFirmCampaignCountRecord[]>([])

    useAuthenticated()

    useEffect(() => {
        const getQCDashboardCounts = async () => {
            setLoading(true)
            const counts = await getQCDashboardIntakeCounts();
            setCampaignCountRecords(counts)
            setLoading(false)
        }

        getQCDashboardCounts()
    }, []);

    return (
        <Box>
            <Typography variant={'h1'} mb={2}>Admin Dashboard</Typography>

            <Box mb={2}>
                <Typography variant={'h2'} mb={2}>QC Counts</Typography>
                <Box>
                    {loading && <CircularProgress/>}
                    <QuickSummary lawFirmCampaignCountRecords={campaignCountRecords} />
                </Box>
            </Box>

            <Box>
                <Typography variant={'h2'} mb={2}>Admin Menu</Typography>
                <AdminMainMenuCards />
            </Box>

        </Box>
    )
}