import React from 'react'
import { Show, SimpleShowLayout, TextField } from "react-admin";


interface Props {}

const VendorShow: React.FC<Props> = (props) => {


    return (
        <>
            <Show {...props}>
                <SimpleShowLayout>
                    <TextField source={'name'}/>
                    <TextField source={'address_line1'}/>
                    <TextField source={'city'}/>
                    <TextField source={'state'}/>
                    <TextField source={'postal_code'}/>
                    {/*<Box>*/}
                    {/*    <WithRecord render={record => (*/}
                    {/*        <Box>*/}
                    {/*            <Box mb={1}>*/}
                    {/*                <Typography variant={'body1'} sx={{ color: ats_default_blue }}>*/}
                    {/*                    <Link*/}
                    {/*                        sx={{ textDecoration: 'none' }}*/}
                    {/*                        target={'_blank'} href={`/law-firms/dashboard?lawFirmId=${record.id}`}>*/}
                    {/*                        View Dashboard as LawFirm*/}
                    {/*                    </Link>*/}
                    {/*                </Typography>*/}
                    {/*            </Box>*/}
                    {/*            <LawFirmCampaigns law_firm_id={record.id}/>*/}
                    {/*        </Box>*/}
                    {/*    )}/>*/}
                    {/*</Box>*/}
                </SimpleShowLayout>

            </Show>
        </>
    )
}

export default VendorShow