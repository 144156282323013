
export interface IHttpGetOptions {
    isPrivate?: boolean
}
export interface IHttpPostOptions {
    body: any
    isFormData?: boolean | undefined
    isPrivate?: boolean | undefined
}

export interface IErrorResponse {
    code: number
    msg: string
}

export const get = async <T>(uri: string, options: IHttpGetOptions | undefined = undefined) : Promise<T | undefined> => {
    try{
        const fetchOptions: any = {}
        if (options?.isPrivate) {
            fetchOptions.headers = {
                Authorization: localStorage.getItem('auth'),
            }

        }
        const fetchResponse = await fetch(`/api${uri}`, fetchOptions)
        return fetchResponse.status === 200 ? await fetchResponse.json() as T : undefined
    } catch (ex){
        console.error(ex)
        return undefined
    }
}

export const post = async <T>(uri: string, options: IHttpPostOptions) : Promise<{response: Response | undefined, data: T | IErrorResponse}> => {
    try {
        const headers: any = {}
        if (options?.isPrivate) {
            headers.Authorization = localStorage.getItem('auth') as string
        }
        if (!options?.isFormData) {
            headers['Content-Type'] = 'application/json'
        }

        // body: options.isFormData ? convertToFormData(options.body) : JSON.stringify(options.body),
        const fetchResponse = await fetch(`/api${uri}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(options.body)
            })

        return !fetchResponse.ok
            ? { response: fetchResponse, data: await fetchResponse.json() as T }
            : { response: fetchResponse, data: await fetchResponse.json() as IErrorResponse }

    }catch (ex){
        return { response: undefined, data: { code: 500, msg: 'Unhandled exception.' } }
    }
}
