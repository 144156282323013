import React from 'react';
import AdminMainMenuCards from "../../components/common/admin/AdminMainMenuCards";

const AdminHome: React.FC = () => {

    return (
        <AdminMainMenuCards />
    )
};

export default AdminHome;
