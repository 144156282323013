import { Edit, EditProps, ListButton, SimpleForm, TextInput, TopToolbar } from "react-admin";
import { Box, Grid } from "@mui/material";
const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const VendorsEdit: React.FC<EditProps> = (props) => {

    return (
        <Edit actions={<EditActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'name'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'address_line1'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'city'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'state'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'postal_code'} />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Edit>
    )
}