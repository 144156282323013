import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { getLawFirmIntake } from "../../services/lawFirmService";
import { getIntOrDefault } from "../../utils";
import { IIntake } from "../../services/campaignService";

import IntakeDetailsView from "../../components/common/intakes/IntakeDetailsView";

interface Props {}

const LawFirmCampaignDetails: React.FC<Props> = () => {
    const { lawFirmId, campaignId, phoneNumber } = useParams()
    const [currentIntake, setCurrentIntake] = useState<IIntake | undefined>(undefined)

    const navigate = useNavigate()

    useEffect(() => {
        const fetchIntake = async () => {
            if(campaignId && phoneNumber){
                const intake = await getLawFirmIntake(getIntOrDefault(campaignId), phoneNumber)
                setCurrentIntake(intake)
            }
        }

        fetchIntake()
    }, [campaignId, phoneNumber]);

    const handleGoBack = () => {
        navigate(`/dashboards/law-firm?lawFirmId=${lawFirmId}`)
    }

    if(!currentIntake){
        return <></>
    }

    return (
        <IntakeDetailsView intake={currentIntake} onGoBack={handleGoBack} />
    )

}

export default LawFirmCampaignDetails