import {
    Create,
    CreateProps,
    ListButton,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    TopToolbar
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";


const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const LawFirmsCreate: React.FC<CreateProps> = (props) => {

    return (
        <Create {...props} actions={<CreateActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>

                        {/* General Information */}
                        <Grid item xs={12}>
                            <Typography variant="h6">General Information</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="name" name={'name'} label="Name" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="account_owner" name={'account_owner'} label="Account Owner" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ReferenceInput label="Prospect Status" source="prospect_status_id" reference="prospect-statuses">
                                <SelectInput fullWidth optionText="name" />
                            </ReferenceInput>
                        </Grid>

                        {/* Address Information */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Address Information</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="address_line1" name={'address_line1'} label="Address Line 1" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="city" name={'city'} label="City" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="state" name={'state'} label="State" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="postal_code" label="Postal Code" />
                        </Grid>

                        {/* Primary Contact Information */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Primary Contact</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="contact_person" name={'contact_person'} label="Contact Person" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="contact_phone_number" name={'contact_phone_number'} label="Contact Phone Number" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="contact_email" name={'contact_email'} label="Contact Email" />
                        </Grid>

                        {/* Primary Billing Information */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Billing Information</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="billing_contact" name={'billing_contact'} label="Billing Contact" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="billing_phone_number" name={'billing_phone_number'} label="Billing Phone Number" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="billing_email" name={'billing_email'} label="Billing Email" />
                        </Grid>

                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                source="notes"
                                name="notes"
                                label="General Notes"
                                multiline
                                minRows={4}
                            />
                        </Grid>

                    </Grid>
                </Box>
            </SimpleForm>
        </Create>
    )
}