import { FC, useState } from "react";
import { AppBar, Box, Container, Drawer, IconButton, Link, Toolbar } from "@mui/material";
import CustomMenu from "./CustomMenu";
import { ats_default_blue } from "../../atsConstants";
import MenuIcon from '@mui/icons-material/Menu';
import ATSLogo from '../../assets/ats-logo.png'

interface Props {}

const CustomAppBar: FC<Props> = (props) => {

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

    const toggleDrawer = (open: boolean) => () => {
        setIsDrawerOpen(open)
    }

    return (
        <Box>
            <AppBar position="fixed" sx={{ backgroundColor: ats_default_blue }}>
                <Toolbar>
                    <Container maxWidth={'lg'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Link href="/" style={{ textDecoration: 'none' }}>
                                <Box>
                                    <img src={ATSLogo} alt="ATS Logo" style={{ height: '40px' }} />
                                </Box>
                            </Link>
                        </Box>
                        <Box>
                            <IconButton
                                edge="end"
                                color="inherit"
                                size="large"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <Drawer anchor={'right'} open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <CustomMenu onMenuItemClicked={() => setIsDrawerOpen(false)} />
            </Drawer>
        </Box>
    )
}

export default CustomAppBar