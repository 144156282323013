import React, { useEffect, useState } from 'react'
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import AdminActionMenu from "../AdminDashboard/AdminActionMenu";
import moment from "moment";
import { IIntake } from "../../../services/campaignService";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";

interface Props {
  currentCampaign: IDashboardCampaign
}

const QCIntakesByCampaignTable: React.FC<Props> = (props) => {
  const { currentCampaign } = props;
  const [  gridData, setGridData] = useState<IIntake[]>([])

  // const [viewMoreIntake, setViewMoreIntake] = useState<IIntake | undefined>(undefined)
  // const [viewMoreDlgOpen, setViewMoreDlgOpen] = useState<boolean>(false)
  //const [currentCampaign, setCurrentCampaign] = useState<IDashboardCampaign | undefined>(undefined);

  const handleViewMoreClicked = (intake: IIntake) => {
    // setViewMoreIntake(intake)
    // setViewMoreDlgOpen(true)
  }

  useEffect(() => {
    const fetchCampaignIntakes = async () => {
      const response = await fetch(`/api/campaigns/${currentCampaign.id}/intakes`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data) {
        setGridData(data.intakes)
        //setCampaignCounts(data.counts)
      }
    }

    if (currentCampaign) {
      //fetchCampaign();
      fetchCampaignIntakes();
    }
  }, [currentCampaign]);

  const columns = [
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   sortable: false,
    //   renderCell: (params: GridRenderCellParams) => (
    //       <AdminActionMenu onViewIntakeClicked={handleViewMoreClicked}  law_firm_id={currentCampaign?.law_firm_id ?? 0} campaign_id={currentCampaign?.id ?? 0} intake={params.row} />
    //   ),
    // },
    { field: 'qc_status', headerName: 'QC Status', width: 150 },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : ''
    },
    { field: 'injured_party_name', headerName: 'Injured Party Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'dob', headerName: 'DOB', width: 150 },
    { field: 'vendor_id', headerName: 'Vendor ID', width: 150 },
    {
      field: 'submitted_to_law_firm_date',
      headerName: 'Submitted to Law Firm Date',
      width: 200,
      valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : ''
    },
  ];

  const rows = gridData.map((row, index) => ({ id: index, ...row }));

    return (
        <>
          {currentCampaign && (
              <Box>
                <Box>
                  <Paper style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                    />
                  </Paper>
                </Box>
              </Box>
          )}
        </>
    )
}

export default QCIntakesByCampaignTable