import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IIntake } from "../../../../services/campaignService";
import { getLawFirmIntake } from "../../../../services/lawFirmService";
import { getIntOrDefault } from "../../../../utils";
import IntakeDetailsView from "../../../../components/common/intakes/IntakeDetailsView";

interface Props {}

const QCIntakeDetails: React.FC<Props> = () => {
    //lawFirmId
    const {  campaignId, phoneNumber } = useParams()
    const [currentIntake, setCurrentIntake] = useState<IIntake | undefined>(undefined)

    const navigate = useNavigate()

    useEffect(() => {
        const fetchIntake = async () => {
            if(campaignId && phoneNumber){
                const intake = await getLawFirmIntake(getIntOrDefault(campaignId), phoneNumber)
                setCurrentIntake(intake)
            }
        }

        fetchIntake()
    }, [campaignId, phoneNumber]);

    const handleGoBack = () => {
        navigate(`/reports/qc-intakes`)
    }

    if(!currentIntake){
        return <></>
    }

    return (
        <IntakeDetailsView intake={currentIntake} onGoBack={handleGoBack} />
    )
}
export default QCIntakeDetails