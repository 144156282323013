import {

    EditProps,
    ListButton, Show,
    SimpleShowLayout,
    TopToolbar, TextField, ReferenceField, BooleanField, DateField, WithRecord
} from "react-admin";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import QCIntakesByCampaignTable from "../../../components/common/intakes/QCIntakesByCampaignTable";
import React from "react";

const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const CampaignsShow: React.FC<EditProps> = (props) => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>

                        {/* Campaign Information */}
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Campaign Information
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Campaign Id: </strong>
                                        <TextField source="ats_campaign_id" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Campaign Name: </strong>
                                        <TextField source="name" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Law Firm: </strong>
                                        <ReferenceField source="law_firm_id" reference="law-firms">
                                            <TextField source="name" />
                                        </ReferenceField>
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Tort: </strong>
                                        <ReferenceField source="tort_id" reference="torts">
                                            <TextField source="name" />
                                        </ReferenceField>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Additional Information */}
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Additional Information
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Retainers: </strong>
                                        <TextField source="retainers" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Google Sheet Name: </strong>
                                        <TextField source="google_sheet_name" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Batch Key: </strong>
                                        <TextField source="batch_key" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Start Date: </strong>
                                        <DateField source="start_date" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>End Date: </strong>
                                        <DateField source="end_date" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Active: </strong>
                                        <BooleanField source="is_active" />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Memo / Details */}
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Details (Memo)
                                    </Typography>
                                    <Typography variant="body2">
                                        <TextField source="details" label="" />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Box my={2}>
                        <WithRecord render={record => (
                            <>
                                <QCIntakesByCampaignTable currentCampaign={record}/>
                            </>
                        )} />
                    </Box>

                </Box>
            </SimpleShowLayout>
        </Show>
    )
}
