import React, { useEffect, useState } from 'react'
import QuickSummary from "../AdminDashboard/QuickSummary";
import { getLawFirmCampaignQCSummaries } from "../../../services/lawFirmService";

interface Props {
  law_firm_id: number
}

const LawFirmCampaigns: React.FC<Props> = (props) => {
  const { law_firm_id } = props
  const [campaignCountRecords, setCampaignCountRecords] = useState<ILawFirmCampaignCountRecord | undefined>(undefined)

  useEffect(() => {
    const getQCDashboardCounts = async () => {
      //setLoading(true)
      const count = await getLawFirmCampaignQCSummaries(law_firm_id);
      if(count){
        setCampaignCountRecords(count)
      }
      //setLoading(false)
    }

    getQCDashboardCounts()
  }, [law_firm_id]);


  return (
        <>
          {campaignCountRecords && <QuickSummary lawFirmCampaignCountRecords={[campaignCountRecords]} />}
        </>
    )
}

export default LawFirmCampaigns