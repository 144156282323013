import { FC } from "react";
import { CreateButton, Datagrid, EditButton, List, TextField, TopToolbar } from "react-admin";

interface Props {}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);


const VendorTypesList: FC<Props> = (props) => {

    return (
        <List {...props} pagination={false} actions={<ListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'id'} />
                <TextField source={'name'} />
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export default VendorTypesList