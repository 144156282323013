import { Edit, EditProps, ListButton, SimpleForm, TextInput, TopToolbar } from "react-admin";
const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const TortsEdit: React.FC<EditProps> = (props) => {

    return (
        <Edit actions={<EditActions/>}>
            <SimpleForm>
                <TextInput name={'name'} source={'name'}></TextInput>
            </SimpleForm>
        </Edit>
    )
}