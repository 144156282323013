import { Create, CreateProps, ListButton, SimpleForm, TextInput, TopToolbar } from "react-admin";


const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const VendorTypeCreate: React.FC<CreateProps> = (props) => {

    return (
        <Create {...props} actions={<CreateActions/>}>
            <SimpleForm>
                <TextInput name={'name'} source={'name'}></TextInput>
            </SimpleForm>
        </Create>
    )
}