import { jwtDecode } from "jwt-decode";

export const loginUser = async (username: string, password: string) => {
    localStorage.removeItem('auth');

    const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    });

    if (response.ok) {
        const data = await response.json();
        // Store the JWT token under the 'auth' key
        localStorage.setItem('auth', data.token);
        return data;
    } else {
        const error = await response.json();
        throw new Error(error.message || 'Login failed');
    }
};

export const getLawFirmFromToken = () => {
    try{
        const token = localStorage.getItem('auth');
        if (token) {
            const decoded = jwtDecode<{ lawfirm: string }>(token);
            return decoded.lawfirm;
        }
        return undefined;
    }
    catch (e){
        console.log(e)
        return undefined
    }
}

export const getRoleFromToken = () => {
    try {
        const token = localStorage.getItem('auth');
        if (token) {
            const decoded = jwtDecode<{ role: string }>(token);
            return decoded.role;
        }
        return undefined;
    }catch (e){
        console.log(e)
        return undefined
    }
}

export const getToken = () => {
    return localStorage.getItem('auth');
}
