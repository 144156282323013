import React from 'react';
import { Admin, Resource, defaultLightTheme, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";

import CustomLayout from "./components/layout/CustomLayout";
import LoginPage from "./pages/LoginPage";


import authProvider from "./auth/authProvider";
import customDataProvider from './httpClient/dataProvider'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { createTheme } from "@mui/material";
import ReturnReasonsList from "./pages/Admin/ReturnReasons/ReturnReasonsList";
import { ReturnReasonsEdit } from "./pages/Admin/ReturnReasons/ReturnReasonsEdit";
import { ReturnReasonCreate } from "./pages/Admin/ReturnReasons/ReturnReasonCreate";


import QCIntakesByCampaign from "./pages/Admin/Reports/qc-intakes-by-campaign/QCIntakesByCampaign";
import TortsList from "./pages/Admin/Torts/TortsList";
import { TortsEdit } from "./pages/Admin/Torts/TortsEdit";
import { TortsCreate } from "./pages/Admin/Torts/TortsCreate";
import LawFirmsList from "./pages/Admin/LawFirms/LawFirmsList";
import { LawFirmsEdit } from "./pages/Admin/LawFirms/LawFirmsEdit";
import { LawFirmsCreate } from "./pages/Admin/LawFirms/LawFirmsCreate";
import CampaignsList from "./pages/Admin/Campaigns/CampaignsList";
import { CampaignsEdit } from "./pages/Admin/Campaigns/CampaignsEdit";
import { CampaignsCreate } from "./pages/Admin/Campaigns/CampaignsCreate";
import AdminHome from "./pages/Admin/AdminHome";
import LawFirmDashboard from "./pages/LawFirm/LawFirmDashboard";
import LawFirmShow from "./pages/Admin/LawFirms/LawFirmShow";
import VendorsList from "./pages/Admin/Vendors/VendorsList";
import { VendorsEdit } from "./pages/Admin/Vendors/VendorsEdit";
import { VendorsCreate } from "./pages/Admin/Vendors/VendorsCreate";
import VendorShow from "./pages/Admin/Vendors/VendorShow";

import VendorTypesList from "./pages/Admin/VendorTypes/VendorTypesList";
import { VendorTypeEdit } from "./pages/Admin/VendorTypes/VendorTypeEdit";
import { VendorTypeCreate } from "./pages/Admin/VendorTypes/VendorTypeCreate";
import { QCDashboard } from "./pages/Admin/dashboards/QCDashboard";
import LawFirmCampaignDetails from "./pages/LawFirm/LawFirmCampaignDetails";
import ReportsHome from "./pages/Admin/Reports/qc-intakes-by-campaign/ReportsHome";
import QCIntakeDetails from "./pages/Admin/Reports/qc-intakes-by-campaign/QCIntakeDetails";
import UsersList from "./pages/Admin/users/UsersList";
import { UsersEdit } from "./pages/Admin/users/UsersEdit";
import { UsersCreate } from "./pages/Admin/users/UsersCreate";
import CustomDashboard from "./components/layout/CustomDashboard";
import { CampaignsShow } from "./pages/Admin/Campaigns/CampaignsShow";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import ResetPassword from "./pages/ResetPassword";

const atsTheme = createTheme({
    ...defaultLightTheme,
    palette: {
        ...defaultLightTheme.palette,
        primary: {
            main: '#374454', // Your ATS default blue color
        },
    },
    typography: {
        h1: {
            fontSize: '2.7rem',
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '2.2rem',
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1.8rem',
            // fontWeight: 400,
            lineHeight: 1.4,
        },
        h4: {
            fontSize: '1.6rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '1.4rem',
            // fontWeight: 400,
            lineHeight: 1.6,
        },
        h6: {
            fontSize: '1.2rem',
            // fontWeight: 400,
            lineHeight: 1.7,
        },
    }
});

function App() {

    return (
        <Admin
            layout={CustomLayout}
            theme={atsTheme}
            authProvider={authProvider}
            loginPage={LoginPage}
            dataProvider={customDataProvider}
        >
            <Resource name="users" list={UsersList} edit={UsersEdit} create={UsersCreate} />
            <Resource name="return-reasons" list={ReturnReasonsList} edit={ReturnReasonsEdit} create={ReturnReasonCreate} />
            <Resource name="law-firms" list={LawFirmsList} edit={LawFirmsEdit} create={LawFirmsCreate} show={LawFirmShow} />
            <Resource name="torts" list={TortsList} edit={TortsEdit} create={TortsCreate} />
            <Resource name="campaigns" list={CampaignsList} edit={CampaignsEdit} create={CampaignsCreate} show={CampaignsShow} />

            <Resource name="vendors" list={VendorsList} edit={VendorsEdit} create={VendorsCreate} show={VendorShow} />
            <Resource name="vendor-types" list={VendorTypesList} edit={VendorTypeEdit} create={VendorTypeCreate} />

            <CustomRoutes>
                <Route path="/admin" element={<AdminHome/>} />
                <Route path="/forgot-password" element={<ForgotPassword/>} />
                <Route path="/reset-password" element={<ResetPassword/>} />
                <Route path="/change-password" element={<ChangePassword/>} />

                <Route path="/dashboards/qc" element={<QCDashboard/>} />
                <Route path="/dashboards/law-firm" element={<LawFirmDashboard/>} />

                <Route path="/qc-intakes/law-firms/:lawFirmId/campaigns/:campaignId/:phoneNumber/details" element={<LawFirmCampaignDetails/>} />

                <Route path="/reports" element={<ReportsHome />} />
                <Route path="/reports/qc-intakes" element={<QCIntakesByCampaign />} />
                <Route path="/reports/qc-intakes/law-firms/:lawFirmId/campaigns/:campaignId/:phoneNumber/details" element={<QCIntakeDetails/>} />

                <Route path="/" element={<CustomDashboard/>} />
            </CustomRoutes>
        </Admin>
    )
}

export default App;
