import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IIntake } from "../../../services/campaignService";
import { MenuItemLink } from "react-admin";
import { getNumbersFromString } from "../../../utils";

interface Props {
    law_firm_id: number
    campaign_id: number
    intake: any | IIntake
    onViewIntakeClicked: (intake: IIntake) => void
}

const AdminActionMenu: React.FC<Props> = (props) => {
    const { intake, onViewIntakeClicked } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        onViewIntakeClicked?.(intake)
        setAnchorEl(null);
    };


    return (
        <>
            <IconButton onClick={handleOpenMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {intake.phone_number && (
                    <MenuItem onClick={handleClose}>
                        View Details
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

export default AdminActionMenu;
