import {
    Create,
    CreateProps,
    ListButton,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    TopToolbar
} from "react-admin";
import { Box, Grid } from "@mui/material";


const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const VendorsCreate: React.FC<CreateProps> = (props) => {

    return (
        <Create {...props} actions={<CreateActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'name'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ReferenceInput source="vendor_type_id" reference="vendor-types">
                                <SelectInput fullWidth optionText="name" />
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'address_line1'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'city'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'state'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'postal_code'} />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Create>
    )
}