import { Edit, EditProps, ListButton, SimpleForm, TextInput, TopToolbar } from "react-admin";

const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const ReturnReasonsEdit: React.FC<EditProps> = (props) => {

    return (
        <Edit actions={<EditActions/>}>
            <SimpleForm>
                <TextInput name={'reason'} source={'reason'}></TextInput>
            </SimpleForm>
        </Edit>
    )
}