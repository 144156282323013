import {
    BooleanInput,
    Create,
    CreateProps,
    ListButton,
    ReferenceInput, SelectInput,
    SimpleForm,
    TextInput,
    TopToolbar
} from "react-admin";
import { Box, Grid } from "@mui/material";

const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const UsersCreate: React.FC<CreateProps> = (props) => {

    return (
        <Create {...props} actions={<CreateActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'email'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'first_name'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'last_name'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'password'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_admin'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_super_admin'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_law_firm'} />
                        </Grid>

                        <ReferenceInput source="law_firm_id" reference="law-firms">
                            <SelectInput fullWidth optionText="name" />
                        </ReferenceInput>

                    </Grid>
                </Box>
            </SimpleForm>
        </Create>
    )
}