import { AuthProvider } from 'react-admin';
import { loginUser } from "../authService";
import { jwtDecode } from "jwt-decode";

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const token = await loginUser(username, password);
        return { token, redirectTo: '/' }
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkAuth: () => {
        const auth = localStorage.getItem('auth');
        if (auth) {
            try {
                jwtDecode(auth);
                return Promise.resolve();
            } catch (error) {
                // If an error is thrown, the token is invalid
                localStorage.removeItem('auth');
                return Promise.reject();
            }
        }
        return Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
        const token = localStorage.getItem('auth');
        if (!token) return Promise.reject("No token found");

        try {
            const decoded: any = jwtDecode(token);
            return Promise.resolve({
                id: decoded.id,
                role: decoded.role
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

export default authProvider;
