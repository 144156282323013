import { FC } from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List,
    Pagination,
    ShowButton,
    TextField,
    TopToolbar
} from "react-admin";

interface Props {}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const LawFirmsList: FC<Props> = (props) => {

    const truncate = (text: string, length: number) =>
        text.length > length ? `${text.slice(0, length)}...` : text;

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Formats to local date format (e.g., MM/DD/YYYY or DD/MM/YYYY)
    };

    return (
        <List {...props} pagination={<Pagination/>} actions={<ListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'id'} />
                <TextField source={'name'} />
                <TextField source={'prospect_status.name'} label={'Status'}/>
                <TextField
                    source={'law_firm_notes[0].note'}
                    label={'Latest Note'}
                    render={(record :any) => truncate(record.law_firm_notes[0].note, 50)}
                    sortable={false}
                />

                <DateField
                    source={'law_firm_notes[0].created_at'}
                    label={'Latest Date'}
                    emptyText=""
                    sortable={false}
                />

                <ShowButton label={'View'}/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export default LawFirmsList
