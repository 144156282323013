import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean
  onClose: () => void
  title?: string | undefined
  children: React.ReactNode
}

const CustomDialog: React.FC<Props> = (props) => {
  const { title, open, onClose, children } = props

  return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={open}
        >
          <DialogTitle>{title ?? 'Dialog'}</DialogTitle>
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose?.()}>Close</Button>
          </DialogActions>
        </Dialog>
    )
}

export default CustomDialog