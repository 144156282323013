import { post } from "./api";

export const changePassword = async (password: string) : Promise<boolean> => {
    const result = await post<any>(`/auth/change-password`, { body: { password }, isPrivate: true })
    return !!result.response?.ok;
}

export const forgotPassword = async (email: string) : Promise<boolean> => {
    const result = await post<any>(`/auth/forgot-password`, { body: { email } })
    return !!result.response?.ok;
}

export const resetPassword = async (token: string, password: string) : Promise<boolean> => {
    const result = await post<any>(`/auth/reset-password`, { body: { token, password } })
    return !!result.response?.ok;
}

