import React, { useState } from 'react';
import { Button, TextField, Container, Box, Typography } from '@mui/material';
import { useNotify, useRedirect } from 'react-admin';
import { forgotPassword } from "../services/authService";

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [isReset, setIsReset] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!email) {
            notify('Email is required', { type: 'warning' });
            return;
        }

        await forgotPassword(email);
        setIsReset(true);
        // notify('Password changed successfully', { type: 'success' });
        //redirect('/'); // Redirect to the homepage or a different route
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send Reset Link
                    </Button>
                    {isReset && (
                        <Typography align="center" sx={{ marginTop: 2 }}>
                            Please check your email for password reset instructions.
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPassword;
