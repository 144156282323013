import {
    Create,
    CreateProps, DateInput,
    ListButton,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    TopToolbar
} from "react-admin";
import { Box, Grid } from "@mui/material";


const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);


export const CampaignsCreate: React.FC<CreateProps> = (props) => {

    return (
        <Create {...props} actions={<CreateActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}> {/* Centralize and contain form width */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth name="ats_campaign_id" source="ats_campaign_id" label="Campaign Id" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth name="name" source="name" label="Campaign Name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferenceInput source="law_firm_id" reference="law-firms">
                                <SelectInput fullWidth optionText="name" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferenceInput source="tort_id" reference="torts">
                                <SelectInput fullWidth optionText="name" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth name="retainers" source="retainers" label="Retainers" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth name="google_sheet_name" source="google_sheet_name" label="Google Sheet Name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth name="batch_key" source="batch_key" label="Batch Key" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateInput fullWidth name="start_date" source="start_date" label="Start Date" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateInput fullWidth name="end_date" source="end_date" label="End Date" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput fullWidth multiline name="details" source="details" label="Details (Memo)" style={{ minHeight: '100px' }} />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Create>


    )
}