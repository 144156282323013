import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getTurnDown, IIntake } from "../../../services/campaignService";
import moment from "moment";

interface Props {
    law_firm_id: number
    campaign_id: number
    intake: any | IIntake,
    onViewIntakeClicked: (intake: IIntake) => void
    onTurnDownClicked: (intake: IIntake) => void
}

const LawFirmActionMenu: React.FC<Props> = (props) => {
    const { campaign_id } = props
    const [hasReturnPending, setHasReturnPending] = useState<boolean | undefined>(undefined)
    const { intake, onViewIntakeClicked, onTurnDownClicked } = props;

    const returnUntil = moment(intake.submitted_to_law_firm_date, 'M/D/YYYY').add('15', 'days');
    const showReturnOption = !hasReturnPending && moment().isBefore(returnUntil) && intake.qc_status?.toLowerCase() === 'submitted to lawfirm';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if(anchorEl != null){
            const fetchTurnDown = async () => {
                const turnDown = await getTurnDown(campaign_id, intake.phone_number)
                if(turnDown){
                    setHasReturnPending(true)
                }else{
                    setHasReturnPending(false)
                }
            }

            fetchTurnDown();
        }else{
            setHasReturnPending(undefined)
        }
    }, [anchorEl, campaign_id, intake.phone_number]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewDetails = () => {
        onViewIntakeClicked?.(intake)
        setAnchorEl(null);
    };

    const handleTurnDown = () => {
        onTurnDownClicked?.(intake);
        setAnchorEl(null);

    };

    return (
        <>
            <IconButton onClick={handleOpenMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {showReturnOption && <MenuItem onClick={handleTurnDown}>Turn Down</MenuItem>}

                {hasReturnPending && <MenuItem onClick={() =>  setAnchorEl(null)}>Turn Down Pending</MenuItem>}

                {intake.phone_number && (
                    <MenuItem onClick={handleViewDetails}>
                        View Details
                    </MenuItem>
                )}

            </Menu>


        </>
    );
}

export default LawFirmActionMenu;
