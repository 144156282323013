import React from 'react'
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import {
    WithRecord,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    EditButton,
    DeleteButton, ReferenceInput, SelectInput, TextInput
} from "react-admin";
import LawFirmCampaigns from "../../../components/common/LawFirm/LawFirmCampaigns";
import { ats_default_blue } from "../../../atsConstants";
import LawFirmUsersTable from "../../../components/common/LawFirm/LawFirmUsersTable";

interface Props {}

const LawFirmShow: React.FC<Props> = (props) => {


    return (
        <>
            <Show {...props}>
                <SimpleShowLayout>
                    <WithRecord render={record => (
                        <Box>
                            <Typography variant={'h1'}>
                                {record.name}
                            </Typography>
                        </Box>
                    )} />

                    <Box mt={3} mb={2}>
                        <Grid container spacing={2}>
                            {/* Contact Info */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Contact Info
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Contact Person: </strong>
                                            <TextField source="contact_person" label="" />
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Phone Number: </strong>
                                            <TextField source="contact_phone_number" label="" />
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Email: </strong>
                                            <TextField source="contact_email" label="" />
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Billing Info */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Billing Info
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Billing Contact: </strong>
                                            <TextField source="billing_contact" label="" />
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Billing Phone Number: </strong>
                                            <TextField source="billing_phone_number" label="" />
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Billing Email: </strong>
                                            <TextField source="billing_email" label="" />
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={2}>
                        {/* Account Owner */}
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        ATS Properties
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Account Owner: </strong>
                                        <TextField source="account_owner" label=""/>
                                    </Typography>

                                    <strong>Prospect Status: </strong>
                                    <TextField source="prospect_status.name" label=""/>

                                    <WithRecord render={record => (
                                        <Typography variant={'body1'} sx={{ color: ats_default_blue }}>
                                            <Link
                                                sx={{ textDecoration: 'none' }}
                                                target={'_blank'} href={`/dashboards/law-firm?lawFirmId=${record.id}`}>
                                                View Dashboard as LawFirm
                                            </Link>
                                        </Typography>
                                    )}/>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Address Information */}
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Address
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Address Line 1: </strong>
                                        <TextField source="address_line1" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>City: </strong>
                                        <TextField source="city" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>State: </strong>
                                        <TextField source="state" label="" />
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Postal Code: </strong>
                                        <TextField source="postal_code" label="" />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    General Notes
                                </Typography>
                                <Typography variant="body2">
                                    <TextField
                                        fullWidth
                                        source="notes"
                                        name="notes"
                                        label=""
                                        multiline
                                        minRows={4}
                                    />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Display associated contacts */}
                    <ReferenceManyField
                        reference="law-firm-contacts"
                        target="law_firm_id"
                        label="Law Firm Contacts"
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="first_name" />
                            <TextField source="last_name" />
                            <TextField source="email" />
                            <TextField source="phone_number" />
                            {/*<EditButton />*/}
                            {/*<DeleteButton />*/}
                        </Datagrid>
                    </ReferenceManyField>

                    <WithRecord render={record => (
                        <>
                            <Box>
                                <Typography variant="h6" mb={2}>
                                    <strong>Law Firm Users</strong>
                                </Typography>
                                <LawFirmUsersTable law_firm_id={record.id} />
                            </Box>
                            <Box>
                                <Typography variant="h6" mb={2}>
                                    <strong>Campaigns</strong>
                                </Typography>
                                <LawFirmCampaigns law_firm_id={record.id}/>
                            </Box>
                        </>
                    )}/>
                </SimpleShowLayout>
            </Show>
        </>
    )
}

export default LawFirmShow