import { FC } from "react";
import { LayoutProps } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import { Box, Container } from "@mui/material";

const CustomLayout: FC<LayoutProps> = ({ children }) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'background.default' }}>
            <CustomAppBar />
            <Container maxWidth={'lg'}>
                <Box my={7} p={2}>
                    {children}
                </Box>
            </Container>
        </Box>
    )
}

export default CustomLayout