import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

// Assuming the data can have any structure, we use a general approach with index signature
interface DataRow {
    [key: string]: any;
}

interface DynamicDataGridProps {
    data: DataRow[]
    customAction?: (params: GridRenderCellParams) => React.ReactNode
}

const formatHeaderName = (fieldName: string): string => {
    return fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
}

const DynamicDataGrid: React.FC<DynamicDataGridProps> = ({ data, customAction }) => {

    const generateColumns = (data: DataRow[]): GridColDef[] => {
        if (data.length === 0) return [];

        // Create a Set to track unique keys
        const uniqueKeys = new Set<string>();

        // Loop through each data object and collect all unique keys
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                uniqueKeys.add(key);
            });
        });

        // Convert the Set of keys to an array and map to GridColDef objects
        return Array.from(uniqueKeys).map((field): GridColDef => ({
            field: field,
            headerName: formatHeaderName(field),
            width: field.length * 12 + 60,  // Basic width calculation
            flex: 1,  // Makes columns stretch to fill grid
            sortable: true
        }));
    };

    const columns = generateColumns(data);

    if(customAction){

        let customActionBlock = {
            field: 'return',
            headerName: 'Return',
            width: 'return'.length * 12 + 60,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {customAction ? customAction(params) : null}
                </>
            ),
        }

        columns.push(customActionBlock)
    }

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                sx={{ minWidth: 650 }}
                rows={data.map((item, index) => ({ id: index, ...item }))}
                columns={columns}
            />
        </div>
    );
};

export default DynamicDataGrid;
