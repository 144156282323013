import React from 'react'
import { useAuthenticated, useGetIdentity } from "react-admin";
import LawFirmDashboard from "../../pages/LawFirm/LawFirmDashboard";
import { Dashboard } from "../../pages/Admin/dashboards/Dashboard";

interface Props {
}

const CustomDashboard: React.FC<Props> = () => {
  useAuthenticated()
  const { identity } = useGetIdentity();

  if(identity?.role){
    if(identity.role === 'law firm'){
      return <LawFirmDashboard/>
    }else if(identity.role === 'admin'){
      return <Dashboard/>
    }
  }


  return (
        <>loading</>
    )
}

export default CustomDashboard